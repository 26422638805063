exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-advice-js": () => import("./../../../src/pages/advice.js" /* webpackChunkName: "component---src-pages-advice-js" */),
  "component---src-pages-agentportal-js": () => import("./../../../src/pages/agentportal.js" /* webpackChunkName: "component---src-pages-agentportal-js" */),
  "component---src-pages-agentquote-index-js": () => import("./../../../src/pages/agentquote/index.js" /* webpackChunkName: "component---src-pages-agentquote-index-js" */),
  "component---src-pages-agentquote-thank-you-js": () => import("./../../../src/pages/agentquote/thank-you.js" /* webpackChunkName: "component---src-pages-agentquote-thank-you-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-company-board-of-directors-js": () => import("./../../../src/pages/company/board-of-directors.js" /* webpackChunkName: "component---src-pages-company-board-of-directors-js" */),
  "component---src-pages-company-leadership-js": () => import("./../../../src/pages/company/leadership.js" /* webpackChunkName: "component---src-pages-company-leadership-js" */),
  "component---src-pages-company-policyholder-alert-js": () => import("./../../../src/pages/company/policyholder-alert.js" /* webpackChunkName: "component---src-pages-company-policyholder-alert-js" */),
  "component---src-pages-company-press-releases-js": () => import("./../../../src/pages/company/press-releases.js" /* webpackChunkName: "component---src-pages-company-press-releases-js" */),
  "component---src-pages-construction-js": () => import("./../../../src/pages/construction.js" /* webpackChunkName: "component---src-pages-construction-js" */),
  "component---src-pages-contact-agent-partnership-js": () => import("./../../../src/pages/contact/agent-partnership.js" /* webpackChunkName: "component---src-pages-contact-agent-partnership-js" */),
  "component---src-pages-contact-claims-services-js": () => import("./../../../src/pages/contact/claims-services.js" /* webpackChunkName: "component---src-pages-contact-claims-services-js" */),
  "component---src-pages-contact-one-model-js": () => import("./../../../src/pages/contact/one-model.js" /* webpackChunkName: "component---src-pages-contact-one-model-js" */),
  "component---src-pages-contact-policy-services-js": () => import("./../../../src/pages/contact/policy-services.js" /* webpackChunkName: "component---src-pages-contact-policy-services-js" */),
  "component---src-pages-contact-policyowner-and-credentialing-js": () => import("./../../../src/pages/contact/policyowner-and-credentialing.js" /* webpackChunkName: "component---src-pages-contact-policyowner-and-credentialing-js" */),
  "component---src-pages-contact-risk-consultants-js": () => import("./../../../src/pages/contact/risk-consultants.js" /* webpackChunkName: "component---src-pages-contact-risk-consultants-js" */),
  "component---src-pages-contact-sales-js": () => import("./../../../src/pages/contact/sales.js" /* webpackChunkName: "component---src-pages-contact-sales-js" */),
  "component---src-pages-contact-technical-support-js": () => import("./../../../src/pages/contact/technical-support.js" /* webpackChunkName: "component---src-pages-contact-technical-support-js" */),
  "component---src-pages-contact-thank-you-contacting-magmutual-js": () => import("./../../../src/pages/contact/thank-you-contacting-magmutual.js" /* webpackChunkName: "component---src-pages-contact-thank-you-contacting-magmutual-js" */),
  "component---src-pages-contact-thank-you-js": () => import("./../../../src/pages/contact/thank-you.js" /* webpackChunkName: "component---src-pages-contact-thank-you-js" */),
  "component---src-pages-customerportal-js": () => import("./../../../src/pages/customerportal.js" /* webpackChunkName: "component---src-pages-customerportal-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learning-js": () => import("./../../../src/pages/learning.js" /* webpackChunkName: "component---src-pages-learning-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-login-to-globalpreference-js": () => import("./../../../src/pages/login-to-globalpreference.js" /* webpackChunkName: "component---src-pages-login-to-globalpreference-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-magpie-goto-up-to-date-php-js": () => import("./../../../src/pages/magpie/goto_UpToDate.php.js" /* webpackChunkName: "component---src-pages-magpie-goto-up-to-date-php-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-mmpodefense-js": () => import("./../../../src/pages/mmpodefense.js" /* webpackChunkName: "component---src-pages-mmpodefense-js" */),
  "component---src-pages-register-agent-js": () => import("./../../../src/pages/register/agent.js" /* webpackChunkName: "component---src-pages-register-agent-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-register-invite-js": () => import("./../../../src/pages/register/invite.js" /* webpackChunkName: "component---src-pages-register-invite-js" */),
  "component---src-pages-register-m-3-customer-js": () => import("./../../../src/pages/register/m3customer.js" /* webpackChunkName: "component---src-pages-register-m-3-customer-js" */),
  "component---src-pages-register-policy-js": () => import("./../../../src/pages/register/policy.js" /* webpackChunkName: "component---src-pages-register-policy-js" */),
  "component---src-pages-register-request-js": () => import("./../../../src/pages/register/request.js" /* webpackChunkName: "component---src-pages-register-request-js" */),
  "component---src-pages-register-success-js": () => import("./../../../src/pages/register/success.js" /* webpackChunkName: "component---src-pages-register-success-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-services-attorneys-and-consultants-js": () => import("./../../../src/pages/services/attorneys-and-consultants.js" /* webpackChunkName: "component---src-pages-services-attorneys-and-consultants-js" */),
  "component---src-templates-article-article-js": () => import("./../../../src/templates/article/article.js" /* webpackChunkName: "component---src-templates-article-article-js" */),
  "component---src-templates-bio-bio-js": () => import("./../../../src/templates/bio/bio.js" /* webpackChunkName: "component---src-templates-bio-bio-js" */),
  "component---src-templates-claims-lesson-claims-lesson-js": () => import("./../../../src/templates/claims-lesson/claims-lesson.js" /* webpackChunkName: "component---src-templates-claims-lesson-claims-lesson-js" */),
  "component---src-templates-course-course-js": () => import("./../../../src/templates/course/course.js" /* webpackChunkName: "component---src-templates-course-course-js" */),
  "component---src-templates-help-topics-help-topics-js": () => import("./../../../src/templates/help-topics/help-topics.js" /* webpackChunkName: "component---src-templates-help-topics-help-topics-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/page/page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-podcast-podcast-js": () => import("./../../../src/templates/podcast/podcast.js" /* webpackChunkName: "component---src-templates-podcast-podcast-js" */),
  "component---src-templates-policyholder-alert-policyholder-alert-js": () => import("./../../../src/templates/policyholder-alert/policyholder-alert.js" /* webpackChunkName: "component---src-templates-policyholder-alert-policyholder-alert-js" */),
  "component---src-templates-press-release-press-release-js": () => import("./../../../src/templates/press-release/press-release.js" /* webpackChunkName: "component---src-templates-press-release-press-release-js" */),
  "component---src-templates-risk-assessment-risk-assessment-js": () => import("./../../../src/templates/risk-assessment/risk-assessment.js" /* webpackChunkName: "component---src-templates-risk-assessment-risk-assessment-js" */),
  "component---src-templates-risk-report-risk-report-js": () => import("./../../../src/templates/risk-report/risk-report.js" /* webpackChunkName: "component---src-templates-risk-report-risk-report-js" */),
  "component---src-templates-seminar-seminar-js": () => import("./../../../src/templates/seminar/seminar.js" /* webpackChunkName: "component---src-templates-seminar-seminar-js" */),
  "component---src-templates-slider-slider-js": () => import("./../../../src/templates/slider/slider.js" /* webpackChunkName: "component---src-templates-slider-slider-js" */),
  "component---src-templates-toolkit-article-toolkit-article-js": () => import("./../../../src/templates/toolkit-article/toolkit-article.js" /* webpackChunkName: "component---src-templates-toolkit-article-toolkit-article-js" */),
  "component---src-templates-toolkit-toolkit-js": () => import("./../../../src/templates/toolkit/toolkit.js" /* webpackChunkName: "component---src-templates-toolkit-toolkit-js" */),
  "component---src-templates-video-video-js": () => import("./../../../src/templates/video/video.js" /* webpackChunkName: "component---src-templates-video-video-js" */)
}

